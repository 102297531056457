<template>
  <div>
    <div class="container" align="left">
      <div style="min-height: 30rem" class="">
        <div class="container" align="left">
          <div class="row d-flex mt-5 justify-content-center">
            <div class="mb-4">
              <h3>
                <strong>{{ $t('Policy_title') }}</strong>
              </h3>
              <p align="center">{{ $t('Policy_title_sub') }}</p>
            </div>
            <p align="left">{{ $t('policy_desc') }}</p>

            <div :class="{'bookmark-links-kr': isLang === 0, 'bookmark-links-en': isLang === 1}">
              <a @click="goSection('01')">{{ $t('policy_sub1_title') }}</a>
              <a @click="goSection('02')">{{ $t('policy_sub2_title') }}</a>
              <a @click="goSection('03')">{{ $t('policy_sub3_title') }}</a>
              <a @click="goSection('04')">{{ $t('policy_sub4_title') }}</a>
              <a @click="goSection('05')">{{ $t('policy_sub5_title') }}</a>
              <a @click="goSection('06')">{{ $t('policy_sub6_title') }}</a>
              <a @click="goSection('07')">{{ $t('policy_sub7_title') }}</a>
              <a @click="goSection('08')">{{ $t('policy_sub8_title') }}</a>
              <a @click="goSection('09')">{{ $t('policy_sub9_title') }}</a>
              <a @click="goSection('10')">{{ $t('policy_sub10_title') }}</a>
              <a @click="goSection('11')">{{ $t('policy_sub11_title') }}</a>
              <a @click="goSection('12')">{{ $t('policy_sub12_title') }}</a>
              <a @click="goSection('13')">{{ $t('policy_sub13_title') }}</a>
            </div>

            <div class="container">
              <div class="row privacy-detail mb-5">
                <section ref="section-01" class="mt-5">
                  <h5 class="mb-4">
                    <strong>{{ $t('policy_sub1_title') }}</strong>
                  </h5>
                  <p>{{ $t('policy_sub1_desc') }}</p>
                </section>
                <section ref="section-02" class="mt-5">
                  <h5 class="mb-4">
                    <strong>{{ $t('policy_sub2_title') }}</strong>
                  </h5>
                  <p>{{ $t('policy_sub2_desc') }}</p>

                  <table class="web table table-bordered">
                    <tbody align="center">
                      <tr style="background-color: #f4f2eb; font-weight: bold">
                        <td width="10%">{{ $t('policy_sub2_table_tr1_td1') }}</td>
                        <td width="25%">{{ $t('policy_sub2_table_tr1_td2') }}</td>
                        <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                        <td width="10%">{{ $t('policy_sub2_table_tr1_td4') }}</td>
                        <td width="25%">{{ $t('policy_sub2_table_tr1_td5') }}</td>
                      </tr>
                      <tr>
                        <td rowspan="4">{{ $t('policy_sub2_table_tr2_td1') }}</td>
                        <td>{{ $t('policy_sub2_table_tr2_td2') }}</td>
                        <td v-html="$t('policy_sub2_table_tr2_td3')"></td>
                        <td>{{ $t('policy_sub2_table_tr2_td4') }}</td>
                        <td rowspan="5" align="left" v-html="$t('policy_sub2_table_tr2_td5')"></td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr3_td2') }}</td>
                        <td v-html="$t('policy_sub2_table_tr3_td3')"></td>
                        <td>{{ $t('policy_sub2_table_tr3_td4') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr4_td2') }}</td>
                        <td>{{ $t('policy_sub2_table_tr4_td3') }}</td>
                        <td>{{ $t('policy_sub2_table_tr4_td4') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr5_td2') }}</td>
                        <td v-html="$t('policy_sub2_table_tr5_td3')"></td>
                        <td>{{ $t('policy_sub2_table_tr5_td4') }}</td>
                      </tr>
                      <tr>
                        <td v-html="$t('policy_sub2_table_tr6_td1')"></td>
                        <td>{{ $t('policy_sub2_table_tr6_td2') }}</td>
                        <td v-html="$t('policy_sub2_table_tr6_td3')"></td>
                        <td>{{ $t('policy_sub2_table_tr6_td4') }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <table class="mobile table table-bordered">
                    <tbody>
                      <tr>
                        <td
                          colspan="3"
                          style="background-color: #f4f2eb; font-weight: bold; text-align: center"
                          v-html="$t('policy_sub2_table_tr2_td1')"
                        ></td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                        <td>{{ $t('policy_sub2_table_tr2_td2') }}</td>
                        <td>{{ $t('policy_sub2_table_tr3_td2') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                        <td v-html="$t('policy_sub2_table_tr2_td3')"></td>
                        <td>{{ $t('policy_sub2_table_tr3_td3') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                        <td colspan="2">{{ $t('policy_sub2_table_tr2_td4') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                        <td colspan="2" v-html="$t('policy_sub2_table_tr2_td5')"></td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td
                          colspan="3"
                          style="background-color: #f4f2eb; font-weight: bold; text-align: center"
                          v-html="$t('policy_sub2_table_tr2_td1')"
                        ></td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                        <td>{{ $t('policy_sub2_table_tr4_td2') }}</td>
                        <td>{{ $t('policy_sub2_table_tr5_td2') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                        <td v-html="$t('policy_sub2_table_tr4_td3')"></td>
                        <td>{{ $t('policy_sub2_table_tr5_td3') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                        <td colspan="2">{{ $t('policy_sub2_table_tr4_td4') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                        <td colspan="2" v-html="$t('policy_sub2_table_tr2_td5')"></td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td
                          colspan="3"
                          style="background-color: #f4f2eb; font-weight: bold; text-align: center"
                          v-html="$t('policy_sub2_table_tr6_td1')"
                        ></td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr1_td2') }}</td>
                        <td colspan="2">{{ $t('policy_sub2_table_tr6_td2') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr1_td3') }}</td>
                        <td colspan="2" v-html="$t('policy_sub2_table_tr6_td3')"></td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr1_td4') }}</td>
                        <td colspan="2">{{ $t('policy_sub2_table_tr6_td4') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub2_table_tr1_td5') }}</td>
                        <td colspan="2" v-html="$t('policy_sub2_table_tr2_td5')"></td>
                      </tr>
                    </tbody>
                  </table>

                  <p class="numText1 font-weight-normal" v-html="$t('policy_sub2_table_desc1')"></p>
                </section>
                <section ref="section-03" class="mt-5">
                  <h5 class="mb-4">
                    <strong>{{ $t('policy_sub3_title') }}</strong>
                  </h5>
                  <p>
                    {{ $t('policy_sub3_desc1') }}
                  </p>
                </section>

                <section ref="section-04" class="mt-5">
                  <h5 class="mb-4">
                    <strong>{{ $t('policy_sub4_title') }}</strong>
                  </h5>
                  <p class="numText1">{{ $t('policy_sub4_desc1') }}</p>

                  <p class="numText2">{{ $t('policy_sub4_desc1_1') }}</p>
                  <p class="numText2">{{ $t('policy_sub4_desc1_2') }}</p>
                  <p class="numText2">{{ $t('policy_sub4_desc1_3') }}</p>
                  <p class="numText2">{{ $t('policy_sub4_desc1_4') }}</p>
                  <p class="numText2">{{ $t('policy_sub4_desc1_5') }}</p>
                  <p class="numText2">{{ $t('policy_sub4_desc1_6') }}</p>
                  <p class="numText2">{{ $t('policy_sub4_desc1_7') }}</p>
                  <p class="numText2">{{ $t('policy_sub4_desc1_8') }}</p>

                  <p class="numText1">{{ $t('policy_sub4_desc2') }}</p>
                </section>

                <section ref="section-05" class="mt-5">
                  <h5 class="mb-4">
                    <strong>{{ $t('policy_sub5_title') }}</strong>
                  </h5>
                  <p class="numText1">{{ $t('policy_sub5_desc1') }}</p>
                  <table class="table table-bordered">
                    <tbody align="center">
                      <tr style="background-color: #f4f2eb; font-weight: bold">
                        <td>{{ $t('policy_sub5_table_tr1_td1') }}</td>
                        <td>{{ $t('policy_sub5_table_tr1_td2') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub5_table_tr2_td1') }}</td>
                        <td>{{ $t('policy_sub5_table_tr2_td2') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub5_table_tr3_td1') }}</td>
                        <td>{{ $t('policy_sub5_table_tr3_td2') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub5_table_tr4_td1') }}</td>
                        <td>{{ $t('policy_sub5_table_tr4_td2') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub5_table_tr5_td1') }}</td>
                        <td>{{ $t('policy_sub5_table_tr5_td2') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub5_table_tr6_td1') }}</td>
                        <td>{{ $t('policy_sub5_table_tr6_td2') }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('policy_sub5_table_tr7_td1') }}</td>
                        <td>{{ $t('policy_sub5_table_tr7_td2') }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <p class="numText1">{{ $t('policy_sub5_desc2') }}</p>
                  <p class="numText1">{{ $t('policy_sub5_desc3') }}</p>
                </section>

                <section ref="section-06" class="mt-5">
                  <h5 class="mb-4">
                    <strong>{{ $t('policy_sub6_title') }}</strong>
                  </h5>
                  <p class="numText1">{{ $t('policy_sub6_desc1') }}</p>
                  <p class="numText1">{{ $t('policy_sub6_desc2') }}</p>

                  <p class="numText1">{{ $t('policy_sub6_desc3') }}</p>
                  <p class="numText2">{{ $t('policy_sub6_desc3_1') }}</p>
                  <p class="numText3">{{ $t('policy_sub6_desc3_2') }}</p>
                  <p class="numText2">{{ $t('policy_sub6_desc3_3') }}</p>
                  <p class="numText3">{{ $t('policy_sub6_desc3_4') }}</p>
                  <p class="numText2">{{ $t('policy_sub6_desc3_5') }}</p>
                  <p class="numText3">{{ $t('policy_sub6_desc3_6') }}</p>
                </section>

                <section ref="section-07" class="mt-5">
                  <h5 class="mb-4">
                    <strong>{{ $t('policy_sub7_title') }}</strong>
                  </h5>
                  <p class="numText1">{{ $t('policy_sub7_desc1') }}</p>
                  <p class="numText2">{{ $t('policy_sub7_desc1_1') }}</p>
                  <p class="numText2">{{ $t('policy_sub7_desc1_2') }}</p>
                  <p class="numText2">{{ $t('policy_sub7_desc1_3') }}</p>
                  <p class="numText2">{{ $t('policy_sub7_desc1_4') }}</p>
                  <p class="numText1">{{ $t('policy_sub7_desc2') }}</p>
                  <p class="numText1">{{ $t('policy_sub7_desc3') }}</p>
                  <p class="numText1">{{ $t('policy_sub7_desc4') }}</p>
                  <p class="numText1">{{ $t('policy_sub7_desc5') }}</p>
                  <p class="numText1">{{ $t('policy_sub7_desc6') }}</p>
                  <p class="numText1">{{ $t('policy_sub7_desc7') }}</p>
                </section>

                <section ref="section-08" class="mt-5">
                  <h5 class="mb-4">
                    <strong>{{ $t('policy_sub8_1_title') }}</strong>
                  </h5>
                  <p class="numText1">{{ $t('policy_sub8_desc1') }}</p>
                  <p class="numText1">{{ $t('policy_sub8_desc2') }}</p>
                  <p class="numText2">{{ $t('policy_sub8_desc2_1') }}</p>
                  <p class="numText3">{{ $t('policy_sub8_desc2_2') }}</p>
                  <p class="numText2">{{ $t('policy_sub8_desc2_3') }}</p>
                  <p class="numText3">{{ $t('policy_sub8_desc2_4') }}</p>
                  <p class="numText2">{{ $t('policy_sub8_desc2_5') }}</p>
                </section>

                <section ref="section-09" class="mt-5">
                  <h5 class="mb-4">
                    <strong>{{ $t('policy_sub9_title') }}</strong>
                  </h5>
                  <p>{{ $t('policy_sub9_desc1') }}</p>
                </section>

                <section ref="section-10" class="mt-5">
                  <h5 class="mb-4">
                    <strong>{{ $t('policy_sub10_title') }}</strong>
                  </h5>
                  <p>{{ $t('policy_sub10_desc1') }}</p>
                  <p class="numText2" v-html="$t('policy_sub10_table_tr1_td1')"></p>
                  <p class="numText2" v-html="$t('policy_sub10_table_tr2_td1')"></p>
                </section>

                <section ref="section-11" class="mt-5">
                  <h5 class="mb-4">
                    <strong>{{ $t('policy_sub11_title') }}</strong>
                  </h5>
                  <p>{{ $t('policy_sub11_desc1') }}</p>
                  <p class="numText1">{{ $t('policy_sub11_desc2') }}</p>
                  <p class="innerText">{{ $t('policy_sub11_desc3') }}<br /></p>
                  <p class="numText1">{{ $t('policy_sub11_desc4') }}</p>
                  <p class="innerText">{{ $t('policy_sub11_desc5') }}<br /></p>
                  <p class="numText1">{{ $t('policy_sub11_desc6') }}</p>
                  <p class="innerText">{{ $t('policy_sub11_desc7') }}<br /></p>
                  <p class="numText1">{{ $t('policy_sub11_desc8') }}</p>
                  <p class="innerText">{{ $t('policy_sub11_desc9') }}<br /></p>
                  <p class="numText1">{{ $t('policy_sub11_desc10') }}</p>
                  <p class="innerText">{{ $t('policy_sub11_desc11') }}<br /></p>
                  <p class="numText1">{{ $t('policy_sub11_desc12') }}</p>
                  <p class="innerText">{{ $t('policy_sub11_desc13') }}<br /></p>
                  <p class="numText1">{{ $t('policy_sub11_desc14') }}</p>
                  <p class="innerText">{{ $t('policy_sub11_desc15') }}<br /></p>
                  <p class="numText1">{{ $t('policy_sub11_desc16') }}</p>
                  <p class="innerText">{{ $t('policy_sub11_desc17') }}<br /></p>
                  <p class="numText1">{{ $t('policy_sub11_desc18') }}</p>
                  <p class="innerText">{{ $t('policy_sub11_desc19') }}<br /></p>
                  <p class="numText1">{{ $t('policy_sub11_desc20') }}</p>
                  <p class="innerText">{{ $t('policy_sub11_desc21') }}<br /></p>
                  <p class="numText1">
                    {{ $t('policy_sub11_desc22') }}
                  </p>
                  <p class="numText1">
                    {{ $t('policy_sub11_desc23') }}
                  </p>
                </section>

                <section ref="section-12" class="mt-5">
                  <h5 class="mb-4">
                    <strong>{{ $t('policy_sub12_title') }}</strong>
                  </h5>
                  <p>{{ $t('policy_sub12_desc1') }}</p>
                  <p class="numText1">{{ $t('policy_sub12_1_desc1') }}</p>
                  <p class="innerText">
                    {{ $t('policy_sub12_1_desc2') }}
                  </p>
                  <p class="innerText">
                    {{ $t('policy_sub12_1_desc3') }}
                  </p>
                  <p class="innerText">
                    {{ $t('policy_sub12_1_desc4') }}
                  </p>
                  <p class="innerText">
                    {{ $t('policy_sub12_1_desc5') }}
                  </p>
                  <p class="numText1">{{ $t('policy_sub12_2_desc1') }}</p>
                  <p class="innerText">{{ $t('policy_sub12_2_desc2') }}</p>
                  <p class="innerText">{{ $t('policy_sub12_2_desc3') }}</p>
                  <p class="innerText">{{ $t('policy_sub12_2_desc4') }}</p>
                  <p class="innerText">{{ $t('policy_sub12_2_desc5') }}</p>

                  <p class="numText1">{{ $t('policy_sub12_3_desc1') }}</p>
                  <p class="innerText">{{ $t('policy_sub12_3_desc2') }}</p>
                  <p class="innerText">{{ $t('policy_sub12_3_desc3') }}</p>

                  <p class="numText1">{{ $t('policy_sub12_4_desc1') }}</p>
                  <p class="innerText">{{ $t('policy_sub12_4_desc2') }}</p>
                  <p class="innerText">{{ $t('policy_sub12_4_desc3') }}</p>
                </section>

                <section ref="section-13" class="mt-5">
                  <h5 class="mb-4">
                    <strong>{{ $t('policy_sub13_title') }}</strong>
                  </h5>
                  <p>{{ $t('policy_sub13_desc1') }}</p>
                  <p class="numText1">{{ $t('policy_sub13_desc2') }}</p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLang: 1,
    };
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
  methods: {
    goSection(idx) {
      this.$refs[`section-${idx}`].scrollIntoView();
    },
    prevPage(idx) {
      this.$parent.launchEditForm(idx);
    },
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
      } else if (newVal === 'en') {
        this.isLang = 1;
      } else if (newVal === 'ja') {
        this.isLang = 2;
      } else {
        this.isLang = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
a,
p,
h1,
h2,
h3,
h4,
h5,
td {
  word-break: keep-all;
}
.bookmark-links-kr a {
  float: left;
  display: flex;
  cursor: pointer;

  border: 1px solid #bfbfbf;
  background-color: #f4f2eb;
  padding: 0.5rem;
  margin: 0.1rem;
  width: 33%;
  height: 3rem;
  align-items: center;

  font-size: 0.8rem;
  box-sizing: border-box;
}
.bookmark-links-en a {
  float: left;
  display: flex;
  cursor: pointer;

  border: 1px solid #bfbfbf;
  background-color: #f4f2eb;
  padding: 0.5rem;
  margin: 0.1rem;
  width: 33%;
  height: 4rem;
  align-items: center;
  font-size: 0.8rem;
  box-sizing: border-box;
}
td {
  vertical-align: middle;
}
a {
  color: #333;
}
.button.b-black {
  margin: 0rem 1rem;
  background-color: #333333;
  color: #fff;
}
.button {
  position: relative;
  display: inline-block;

  box-sizing: border-box;
  font-size: 0.9rem;
  text-align: center;
}
.numText1 {
  margin-left: 1rem;
  text-indent: -1rem;
  font-weight: bold;
}
.numText2 {
  margin-left: 2.5rem;
  text-indent: -1.5rem;
}
.numText3 {
  margin-left: 2.5rem;
  text-indent: 0rem;
}
.innerText {
  margin: 1rem;
}
table_line_align {
  font-size: 0.88rem;
  margin-left: 1rem;
  text-indent: -1rem;
}

@media (min-width: 1200px) {
  .web {
    display: table;
  }
  .mobile {
    display: none;
  }
  .bookmark-links-kr a {
    width: 33%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .web {
    display: table;
  }
  .mobile {
    display: none;
  }
  .bookmark-links-kr a {
    width: 49%;
  }
  .bookmark-links-en a {
    width: 49%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .web {
    display: none;
  }
  .mobile {
    display: table;
  }
  .bookmark-links-kr a {
    width: 49%;
  }
  .bookmark-links-en a {
    width: 49%;
  }
}
@media (max-width: 767px) {
  .web {
    display: none;
  }
  .mobile {
    display: table;
  }
  .bookmark-links-kr a {
    width: 100%;
  }
  .bookmark-links-en a {
    width: 100%;
  }
}
</style>

<i18n>
{
  "ko": {
    "Policy_title": "개인정보 처리방침",
    "Policy_title_sub": "(입사지원자용)",
    "policy_desc": "SK핀크스(주)(이하 '회사'라 함)는 고객의 개인정보를 매우 중요하게 생각하며, ‘개인정보보호법’ 등 관련법령을 준수하고 있습니다. 회사는 개인정보 처리방침을 통하여 입사지원자께서 제공하신 개인정보가 어떠한 용도와 방식으로 처리되고 있고, 개인정보보호를 위하여 어떤 조치가 취해지고 있는지 알려드립니다. 회사는 개인정보 처리방침을 개정하는 경우, 웹사이트 공지사항 (또는 개별공지)을 통하여 공지할 것입니다. 회사의 개인정보 처리방침은 다음과 같은 내용을 담고 있습니다.",
    "policy_sub1_title": "제 1조 개인정보의 처리 목적",
    "policy_sub2_title": "제 2조 개인정보의 수집항목 및 이용목적",
    "policy_sub3_title": "제 3조 개인정보의 처리기간",
    "policy_sub4_title": "제 4조 개인정보의 제3자 제공",
    "policy_sub5_title": "제 5조 개인정보 수탁업체 및 업무내용",
    "policy_sub6_title": "제 6조 개인정보의 파기절차 및 방법",
    "policy_sub7_title": "제 7조 정보주체의 권리 및 행사방법",
    "policy_sub8_title": "제 8조 개인정보를 자동으로 수집하는 장치의 설치/ 운영 및 거부에 관한 사항",
    "policy_sub8_1_title" : "제 8조 개인정보를 자동으로 수집하는 장치의 설치/ 운영 및 거부에 관한 사항",
    "policy_sub9_title": "제 9조 만14세 미만 아동의 개인정보보호",
    "policy_sub10_title": "제 10조 개인정보 보호책임자 및 열람 청구 접수 및 처리부서",
    "policy_sub11_title": "제 11조 개인정보의 안전성 확보조치",
    "policy_sub12_title": "제 12조 정보주체의 권익침해에 대한 구제방법",
    "policy_sub13_title": "제 13조 개인정보 처리방침의 변경에 관한 사항",
    "policy_sub1_desc": "회사는 입사지원을 위한 개인정보외에는 사이트 이용을 위하여 필요한 최소한의 범위로 개인정보를 수집하고 있습니다. 정보주체가 본 홈페이지의 개인정보 수집 및 이용목적, 개인정보 3자제공, 민감정보 처리의 내용에 대하여 각각 “동의” 또는 “동의 안함”을 선택할 수 있는 절차를 마련하고 있습니다. 단, 입사 및 근로계약등에 필요한 최소한의 개인정보 수집이 불가능한 경우에는 정상적인 절차가 되지 않을 수 있습니다.",
    "policy_sub2_desc": "회사는 다음과 같은 목적을 위해 개인정보를 수집하고 있습니다.",
    "policy_sub2_table_tr1_td1": "구분",
    "policy_sub2_table_tr1_td2": "수집항목",
    "policy_sub2_table_tr1_td3": "수집목적",
    "policy_sub2_table_tr1_td4": "수집방법",
    "policy_sub2_table_tr1_td5": "보유기간",
    "policy_sub2_table_tr2_td1": "일반정보",
    "policy_sub2_table_tr2_td2": "사진, 성명(한글,한자,영문), 생년월일, 국적",
    "policy_sub2_table_tr2_td3": "본인확인 및 식별, 입사지원서비스 제공",
    "policy_sub2_table_tr2_td4": "홈페이지",
    "policy_sub2_table_tr2_td5": "⋅입사자: 퇴직후 3년, 별도요청시 즉시 파기<br/><br/>⋅입사 예정자: 퇴직후 3년, 별도요청시 즉시 파기<br/><br/>⋅입사포기자: 지원서 제출일로부터 3년, 별도요청시 즉시파기<br/><br/>⋅이외지원자: 지원서 제출일로부터 3년, 별도요청시 즉시파기",
    "policy_sub2_table_tr3_td2": "연락처(유선,무선), 이메일, 주소",
    "policy_sub2_table_tr3_td3": "채용관련 고지사항 전달 및 결과 안내 등",
    "policy_sub2_table_tr3_td4": "홈페이지",
    "policy_sub2_table_tr4_td2": "학력사항, 병역사항, 희망부서, 희망직무, 희망연봉, 자기소개서",
    "policy_sub2_table_tr4_td3": "채용 적합성판단 및 서류심사/면접 등의 근거자료 활용",
    "policy_sub2_table_tr4_td4": "홈페이지",
    "policy_sub2_table_tr5_td2": "경력사원, 최종연봉, 외국어능력, PC활용, 영어회화, 일어회화, 중국어회화, 자격사항, 해외체류 경험",
    "policy_sub2_table_tr5_td3": "채용 적합성판단 및 서류 심사/면접등의 근거자료 활용",
    "policy_sub2_table_tr5_td4": "홈페이지",
    "policy_sub2_table_tr6_td1": "민감정보",
    "policy_sub2_table_tr6_td2": "보훈대상여부, 보훈번호, 관계, 장애종류, 장애급수, 취업보호계층여부, 해당 세부사항",
    "policy_sub2_table_tr6_td3": "대상자 가산점 부여",
    "policy_sub2_table_tr6_td4": "홈페이지",
    "policy_sub2_table_desc1": "※ 서비스 이용과정에서 아래의 개인정보 항목이 생성되어 수집될 수 있습니다.<br />- IP주소, 쿠키, 서비스이용기록, 방문기록, 상담시 통화내역",
    "policy_sub3_desc1": "회사는 홈페이지 입사지원자 개인정보 삭제 요청시에는 수집된 개인정보가 열람 또는 이용될 수 없도록 (즉시) 처리합니다.",
    "policy_sub4_desc1": "1. 회사는 정보주체의 개인정보를 '개인정보의 처리목적', '수집하는 개인정보 항목 및 수집방법' 에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.",
    "policy_sub4_desc1_1": "가. 정보주체로부터 별도의 동의를 받은 경우",
    "policy_sub4_desc1_2": "나. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우",
    "policy_sub4_desc1_3": "다. 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우",
    "policy_sub4_desc1_4": "라. 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서 보호위원회의 심의-의결을 거친 경우",
    "policy_sub4_desc1_5": "마. 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한 경우",
    "policy_sub4_desc1_6": "바. 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우",
    "policy_sub4_desc1_7": "사. 법원의 재판업무 수행을 위하여 필요한 경우",
    "policy_sub4_desc1_8": "아. 형(刑) 및 감호, 보호처분의 집행을 위하여 필요한 경우",
    "policy_sub4_desc2": "2. 회사는 재난, 감염병, 급박한 생명∙신체 위험을 초래하는 사건∙사고. 급박한 재산 손실 등의 긴급상황이 발생하는 경우 정보주체의 동의 없이 관계기관에 개인정보를 제공할 수 있습니다. 이 경우 회사는 근거법령에 의거하여 필요한 최소한의 개인정보만을 제공하며, 목적과 다르게 제공하지 않겠습니다.",
    "policy_sub5_desc1": "1. 회사는 개인정보 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계법령에 따라 위탁 계약시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.",
    "policy_sub5_table_tr1_td1": "수탁업체",
    "policy_sub5_table_tr1_td2": "수탁업무내용",
    "policy_sub5_table_tr2_td1": "SK네트웍스서비스㈜",
    "policy_sub5_table_tr2_td2": "홈페이지 운영 및 관리",
    "policy_sub5_table_tr3_td1": "비즈톡 주식회사",
    "policy_sub5_table_tr3_td2": "문자, 알림톡 발송 서비스",
    "policy_sub5_table_tr4_td1": "SCI평가정보㈜",
    "policy_sub5_table_tr4_td2": "휴대폰을 통한 본인인증",
    "policy_sub5_table_tr5_td1": "한국행동과학연구소",
    "policy_sub5_table_tr5_td2": "인적성 검사",
    "policy_sub5_table_tr6_td1": "BSC",
    "policy_sub5_table_tr6_td2": "인적성 검사",
    "policy_sub5_table_tr7_td1": "ACG",
    "policy_sub5_table_tr7_td2": "인적성 검사",
    "policy_sub5_desc2": "2. 회사는 위탁계약 체결시 개인정보보호법 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적∙관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리∙감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.",
    "policy_sub5_desc3": "3. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다. 회사는 입사지원자의 유지 관리, 상담, 기타 안내를 위하여 전문 용역 업체에 일부 업무를 위탁 운영할 수 있습니다. 위탁을 받은 업체는 위탁을 받은 목적을 벗어나서 개인정보를 이용할 수 없습니다. 회사는 위탁처리되는 개인정보가 안전하게 관리될 수 있도록 수탁업체가 규정한 사항을 성실하게 이행하는 지 여부에 대하여 위탁한 업무의 범위내에서 적절한 감독을 행할 것입니다.",
    "policy_sub6_desc1": "1. 회사는 개인정보의 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.",
    "policy_sub6_desc2": "2. 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 일정기간 보관된 후 파기됩니다. 별도 데이터베이스 (종이문서의 경우 별도의 서류함)로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되는 목적 이외의 다른 목적으로 이용되지 않습니다.",
    "policy_sub6_desc3": "3. 개인정보의 파기절차, 파기기한, 파기방법은 다음과 같습니다.",
    "policy_sub6_desc3_1": "1) 파기절차",
    "policy_sub6_desc3_2": "회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.",
    "policy_sub6_desc3_3": "2) 파기방법",
    "policy_sub6_desc3_4": "회사는 전자적 파일 형태로 기록∙저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이문서에 기록∙저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.",
    "policy_sub6_desc3_5": "3) 파기기한",
    "policy_sub6_desc3_6": "정보주체의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에 파기하며 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.",
    "policy_sub7_desc1": "1. 정보주체는 회사에 대해 언제든지 다음과 같이 개인정보보호 관련 권리를 행사할 수 있습니다.",
    "policy_sub7_desc1_1": "가. 개인정보 열람요구",
    "policy_sub7_desc1_2": "나. 오류 등이 있을 경우 정정 요구",
    "policy_sub7_desc1_3": "다. 삭제요구",
    "policy_sub7_desc1_4": "라. 처리정지 요구",
    "policy_sub7_desc2": "2. 제1항에 따른 권리행사는 회사에 대해 개인정보 보호법 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다. (개인정보 처리에 관한 고시(제2020-7호) 별지 제8호, 제10호 서식 참조)",
    "policy_sub7_desc3": "3. 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 해당 개인정보를 이용하거나 제공하지 않습니다.",
    "policy_sub7_desc4": "4. 제1항에 따른 권리행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 개인정보 처리 방법에 관한 고시(제2020-7호) 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.",
    "policy_sub7_desc5": "5. 개인정보 열람 및 처리정지 요구는 개인정보보호법에 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.",
    "policy_sub7_desc6": "6. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.",
    "policy_sub7_desc7": "7. 회사는 정보주체 권리에 따른 열람의 요구, 정정∙삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.",
    "policy_sub8_desc1": "1. 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.",
    "policy_sub8_desc2": "2. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.",
    "policy_sub8_desc2_1": "가. 쿠키(cookie)의 운용",
    "policy_sub8_desc2_2": "이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용행태, 인기검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.",
    "policy_sub8_desc2_3": "나. 쿠키(cookie)의 설치/운영 및 거부",
    "policy_sub8_desc2_4": "이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 웹브라우져에서 옵션을 설정하여 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치게 하거나 아니면 쿠기의 저장을 거부할 수 있습니다.",
    "policy_sub8_desc2_5": "다. 쿠키(cookie) 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.",
    "policy_sub9_desc1": "회사는 만14세 미만 아동의 개인정보를 수집하지 않습니다.",
    "policy_sub10_desc1": "회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 개인정보와 관련된 문의사항이 있으시면 아래의 개인정보 보호책임자/보호담당자에게 연락주시기 바랍니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.",
    "policy_sub10_table_tr1_td1": "<strong>개인정보보호 책임자</strong><br />- 성명 : 김대훈<br />- 소속 : 경영지원담당<br />- 직책 : 경영지원담당 이사<br />- 이메일 : skpinx_it@skpinx.co.kr<br />- 전화번호 : 064-793-6080<br /><br />",
    "policy_sub10_table_tr2_td1": "<strong>개인정보보호 담당자</strong><br />- 성명 : 오예슬<br />- 소속 : ESG TF<br />- 직책 : 매니저<br />- 이메일 : yeseul12@sk.com<br/>- 전화번호 : 064-793-5016",
    "policy_sub11_desc1": "회사는 개인정보보호법 제29조에 따라 다음과 같이 안정성 확보에 필요한 기술적/관리적 및 물리적 조치를 취하고 있습니다.",
    "policy_sub11_desc2": "1. 개인정보 처리 인력의 최소화",
    "policy_sub11_desc3": "개인정보보호를 위해 개인정보 처리자에 대한 권한 부여를 최소화하고 있습니다.",
    "policy_sub11_desc4": "2. 개인정보 처리 인력에 대한 정기적 교육 시행",
    "policy_sub11_desc5": "개인정보보호에 대한 인식 제고를 위해 정기적인 교육을 시행하고 있습니다.",
    "policy_sub11_desc6": "3. 내부 점검 정기적 시행",
    "policy_sub11_desc7": "개인정보의 처리 관련 안정성 확보를 위해 정기적으로 자체 점검을 실시하고 있습니다.",
    "policy_sub11_desc8": "4. 내부 관리계획의 수립 및 시행",
    "policy_sub11_desc9": "개인정보의 안전한 처리 및 관리를 위해 내부관리계획을 수립하여 관리하고 있습니다.",
    "policy_sub11_desc10": "5. 개인정보의 암호화",
    "policy_sub11_desc11": "이용자의 개인정보와 비밀번호는 암호화되어 저장/관리되고 있으며 전송 시에도 별도의 보안기능을 사용하여 안전하게 관리하고 있습니다.",
    "policy_sub11_desc12": "6. 해킹 등에 대비한 기술적 대책",
    "policy_sub11_desc13": "회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안 프로그램을 설치하고 주기적인 갱신/점검을 하여 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적, 물리적으로 감시 및 차단하고 있습니다.",
    "policy_sub11_desc14": "7. 개인정보에 대한 접근 제한",
    "policy_sub11_desc15": "개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단 시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.",
    "policy_sub11_desc16": "8. 접속기록의 보관 및 위변조 방지",
    "policy_sub11_desc17": "개인정보 처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며 접속 기록이 위변조 및 도난 분실 되지 않도록 보안기능을 사용하고 있습니다.",
    "policy_sub11_desc18": "9. 문서보안을 위한 잠금장치 사용",
    "policy_sub11_desc19": "개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.",
    "policy_sub11_desc20": "10. 비인가자에 대한 출입 통제",
    "policy_sub11_desc21": "개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.",
    "policy_sub11_desc22": "11. 정보주체 본인의 부주의나 인터넷 상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.",
    "policy_sub11_desc23": "12. 회사는 개인정보의 유출∙훼손 등 개인정보 침해사고 발생시 지체없이 정보주체에게 알리고 그 피해를 최소화 하기 위해 필요한 조치를 다합니다.",
    "policy_sub12_desc1": "정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.",
    "policy_sub12_1_desc1": "1. 개인정보 침해신고센터 (한국인터넷진흥원 운영)",
    "policy_sub12_1_desc2": "- 소관업무 : 개인정보 침해사실 신고, 상담신청",
    "policy_sub12_1_desc3": "- 홈페이지 : privacy.kisa.or.kr",
    "policy_sub12_1_desc4": "- 전화 : (국번없이) 118",
    "policy_sub12_1_desc5": "- 주소 : (58324) 전남 나주시 진흥길 9(빛가림동 301-2) 3층 개인정보침해신고센터",
    "policy_sub12_2_desc1": "2. 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)",
    "policy_sub12_2_desc2": "- 소관업무 : 개인정보 분쟁조정 신청, 집단분쟁조정 (민사적 해결)",
    "policy_sub12_2_desc3": "- 홈페이지 : www.kopico.go.kr",
    "policy_sub12_2_desc4": "- 전화 : (국번없이) 1833-6972",
    "policy_sub12_2_desc5": "- 주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 12층",
    "policy_sub12_3_desc1": "3. 대검찰청 사이버수사과",
    "policy_sub12_3_desc2": "- 전화 : (국번없이) 1301",
    "policy_sub12_3_desc3": "- 홈페이지 : www.spo.go.kr",
    "policy_sub12_4_desc1": "4. 경찰청 사이버수사국",
    "policy_sub12_4_desc2": "- 전화 : (국번없이) 182",
    "policy_sub12_4_desc3": "- 홈페이지 : ecrm.cyber.go.kr",
    "policy_sub13_desc1": "개인정보 처리방침은 2024년 2월 23일에 제정되었으며, 법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 시에는 변경되는 개인정보 처리방침은 홈페이지를 통해 변경 이유 및 내용 등을 공지하도록 하겠습니다.",
    "policy_sub13_desc2": "- 시행일자 : 2024년 02월 23일"
  }
}
</i18n>
